if (module.hot) {
  module.hot.accept();
}

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'slick-carousel';
import 'waypoints/lib/noframework.waypoints.min.js';
var Masonry = require('masonry-layout');

var breakpointMobile = 768;

window.onload = function() {
  setTimeout(function(){$('main.holder').removeClass('prevent-transition');}, 100);
}

$(document).ready(function(){

  /*var msnry = new Masonry( '.schedule-grid', {
    // options...
  });*/

  $('.inspiration').slick({
    dots: true,
  });

  $('.floorplan-selectors button').click(function(){
    var newFloor = $('.floorplan-selectors button').index(this);
    $('.floorplan-selectors button, .floorplan-images .floorplan').removeClass('selected');
    this.classList.add('selected');
    $('.floorplan-images .floorplan')[newFloor].classList.add('selected');
  })

  $('.play-audio').click(function(){
    console.log($('.audio-target')[0]);
    if ($('.audio-target')[0].paused) {
        $('.audio-target')[0].play();
    } else {
        $('.audio-target')[0].pause();
    }
  })
})
